// POST Browser 分量 => angel 隐藏
const channel = 'ume-apk-us-en'
const api_key = '323f3df34d29e70c2757d87e32cfe17558827312'
const source_id = channel + 'angel_web'
const view_id = '375f052386fe41d29ed6f20bb8d4730f'
const placements = [
  { name: 'Editorial Trending Thumbnails-1-1', recCount: 1, organicType: 'mix', thumbnail: { width: 750, height: 350 } },
  { name: 'Editorial Trending Thumbnails-1-2', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'AD Editorial Trending Thumbnails-1-1', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'Editorial Trending Thumbnails-1-3', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'Editorial Trending Thumbnails-1-4', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } },
  { name: 'AD Editorial Trending Thumbnails-1-2', recCount: 1, organicType: 'mix', thumbnail: { width: 215, height: 145 } }
]

// Analytics
const eventName = 'angel_web'

const base_url = 'news.angel.sve.cc'

export { channel, api_key, source_id, view_id, placements, eventName, base_url }