// 网鑫
// brwoser => news.web.sve.cc
// export { channel, api_key, source_id, view_id, placements, eventName, base_url } from './config-browser'
// browser 分支 => tangle.web.sve.cc
// export { channel, api_key, source_id, view_id, placements, eventName } from './config-browser-branch'
// web => newspro.web.sve.cc
// export { channel, api_key, source_id, view_id, placements, eventName } from './config-web'
// newsplus 两印 => newsplus.web.sve.cc
// export { channel, api_key, source_id, view_id, placements, eventName } from './config-in'
// newsturbo 拉美 => newsturbo.web.sve.cc
// export { channel, api_key, source_id, view_id, placements, eventName } from './config-latin'

// 天使
// angel => news.angel.sve.cc (angel.web.sve.cc => 废弃)
export { channel, api_key, source_id, view_id, placements, eventName } from './config-angel'

// search 申请GCS => xrainy.com
// export { channel, api_key, source_id, view_id, placements, eventName } from './config-search'